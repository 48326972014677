<template>
    <div>
        <step-bar :active="1" @clickStep="clickStep" :forward="!showBack"></step-bar>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>《电力客户供电方案勘察表》</span>
            </div>
            <div class="commonNotice">
                <ex-card headerName="业务信息">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24" v-for="(item,index) in businessInfo" :key="index">
                            <el-col :span="6" style="text-align:right;">
                                <span>{{item.name}}:</span>
                            </el-col>
                            <el-col :span="18" style="text-align:left;">
                                <span>{{item.value}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="接入方式">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24" v-for="(item,index) in accessMethod" :key="index">
                            <el-col :span="6" style="text-align:right;">
                                <span>{{item.name}}:</span>
                            </el-col>
                            <el-col :span="18" style="text-align:left;">
                                <span>{{item.value}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="初步接入方案">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24" v-for="(item,index) in initialAccessPlan" :key="index">
                            <el-col :span="6" style="text-align:right;">
                                <span>{{item.name}}:</span>
                            </el-col>
                            <el-col :span="18" style="text-align:left;">
                                <span>{{item.value}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="现场勘察情况">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24" v-for="(item,index) in onSiteInvestigation" :key="index">
                            <el-col :span="6" style="text-align:right;">
                                <span>{{item.name}}:</span>
                            </el-col>
                            <el-col :span="18" style="text-align:left;">
                                <span>{{item.value}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="推荐电源点选择原因">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24" v-for="(item,index) in powerPointSelectionReason" :key="index">
                            <el-col :span="6" style="text-align:right;">
                                <span>{{item.name}}:</span>
                            </el-col>
                            <el-col :span="18" style="text-align:left;">
                                <span>{{item.value}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="勘察意见">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24" v-for="(item,index) in surveyOpinion" :key="index">
                            <el-col :span="6" style="text-align:right;">
                                <span>{{item.name}}:</span>
                            </el-col>
                            <el-col :span="18" style="text-align:left;">
                                <span>{{item.value}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
            </div>
        </el-card>

        <el-row v-if="showBack" :gutter="24" class="submit-btn-group">
        <el-col :span="24">
            <el-button @click="back" style="position: absolute;left:0; top:0; right:0; bottom: 0; margin: auto;height:26px;">返回</el-button>
        </el-col>
    </el-row>
    </div>
</template>

<script>
import axios from '@/client/axios';
import StepBar from '@/components/StepBar/index';
export default {
    name: 'ReconnaissanceShow',
    components: {
        StepBar
    },
    props: {
        id: {
            type: [String, Number],
            default: '0'
        },
        showBack: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            businessInfo: [],
            accessMethod: [],
            initialAccessPlan: [],
            onSiteInvestigation: [],
            powerPointSelectionReason: [],
            surveyOpinion: [],
            keyValues: {
                elecNature: [],
                elecType: []
            },
        };
    },
    methods: {
        async bindKeyValues() {
            // const data = await Promise.all([
            //     this.$client.getDicData('elec_nature'),
            //     this.$client.getDicData('elec_type')
            // ]);
            // this.keyValues.elecNature = data[0].data || [];
            // this.keyValues.elecType = data[1].data || [];

            const dictMap = [
                {parentCode: 'elec_nature', listName: 'elecNature'},
                {parentCode: 'elec_type', listName: 'elecType'},
            ];
            this.$client.getDicDataArr(dictMap.map((it) => it.parentCode)).then((res) => {
                let {data: resData} = res;
                for (let key in resData) {
                    let data = resData[key];
                    let dict = dictMap.filter((it) => it.parentCode === key)[0];
                    this.keyValues[dict.listName] = data;
                }
            });
        },
        loadData() {
            this.$client.getReconnaissanceInfo(this.id).then((data) => {
                // 业务信息
                this.businessInfo.push({name: '客户名称', value: data.data.customerName});
                this.businessInfo.push({name: '工作单号', value: data.data.businessNumber});
                this.businessInfo.push({name: '用电地址', value: data.data.useAddr});
                this.businessInfo.push({name: '业务类别', value: data.data.businessType});
                let items = this.keyValues.elecNature.filter(e => parseInt(e.itemKey) === data.data.elecNature);
                this.businessInfo.push({name: '用电性质', value: items.length > 0 ? items[0].itemValue : data.data.elecNature});
                this.businessInfo.push({name: '原有容量', value: data.data.oldCapacity});
                this.businessInfo.push({name: '增/减容量', value: data.data.updateCapacity});
                this.businessInfo.push({name: '总容量', value: data.data.totalCapacity});
                items = this.keyValues.elecType.filter(e => parseInt(e.itemKey) === data.data.elecType);
                this.businessInfo.push({name: '用电类别', value: items.length > 0 ? items[0].itemValue : data.data.elecType});
                this.businessInfo.push({name: '高可靠性供电', value: data.data.highPower ? '是' : '否'});
                this.businessInfo.push({name: '申请高可靠容量', value: data.data.highPowerCapacity});
                this.businessInfo.push({name: '是否需要配备应急电源', value: data.data.emergencyPower ? '是' : '否'});

                // 接入方式
                this.accessMethod.push({name: '变压器',
                    value: JSON.parse(data.data.transformerCapacitys).map(item => {
                        return `${item.transformerCapacity}${item.num}台`;
                    }).join('\r\n')});
                items = [];
                if (data.data.singerPowerWay) {
                    items.push('单电源接入');
                }
                if (data.data.highPowerWay) {
                    items.push('高可靠性供电（多电源）');
                }
                if (data.data.communityWay) {
                    items.push('住宅小区配电站环网接入');
                }
                this.accessMethod.push({name: '接入属性', value: items.join(',')});

                this.initialAccessPlan = data.data.preReconnaissancePlans.map((item, index) => {
                    return {
                        name: '电源点' + (index + 1),
                        value: `${item.powerAp} 进线距离：${item.lineDistance} 负载余量：${item.surplusCapacity} 线路属性：${item.lineType} 额定容量：${item.ratedCapacity} (KVA) 在途容量：${item.usedCapacity} (KVA)`
                    };
                });

                let recommendPower = (data.data.recommendPower || '').split(',');
                let recommendPowerArr = recommendPower.map(item => {
                    let index = 0;
                    for (let i in data.data.preReconnaissancePlans) {
                        if (data.data.preReconnaissancePlans[i].apId === parseInt(item)) {
                            index = Number(i) + 1;
                            break;
                        }
                    }
                    return `电源点${index}`;
                });
                console.log(recommendPowerArr);
                this.onSiteInvestigation.push({name: '推荐电源', value: recommendPowerArr.join(',')});
                this.onSiteInvestigation.push({name: '其他', value: (data.data.otherReconnaissancePlans || []).map(item => `${item.powerAp} 进线距离：${item.lineDistance} 负载余量：${item.surplusCapacity} 线路属性：${item.lineType} 额定容量：${item.ratedCapacity} (KVA) 在途容量：${item.usedCapacity} (KVA)`).join('\r\n')});

                this.powerPointSelectionReason.push({name: '选择原因', value: data.data.recommendReason});
                this.powerPointSelectionReason.push({name: '是否需要实施业扩工程配套项目', value: data.data.isAssortProject === 1 ? '是' : '否'});
                this.powerPointSelectionReason.push({name: '其它', value: data.data.otherNote});

                this.surveyOpinion.push({name: '业扩勘察人(市场部)', value: data.data.reconnaissanceSCSignature.signatureAdvice});
                this.surveyOpinion.push({name: '业扩勘察人(发计部)', value: data.data.reconnaissanceFJSignature.signatureAdvice});
            });
        },
        back() {
            this.$emit('closePanel');
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        }
    },
    async created() {
        await this.bindKeyValues();
        await this.loadData();
    }
};
</script>

<style lang="less" scoped>
.equipment .el-card__body{
    padding: 0;
}
</style>
